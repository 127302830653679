.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.trackpodsettings .Polaris-Layout__AnnotationContent {
  padding-top: 1rem !important;
}


.trackpodsettings .Polaris-TextContainer>*:not(:first-child) {
  margin-top: 0px !important;
}

.trackpodstatusbtn {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.trackpodstatus .Polaris-LegacyCard__Section {
  padding-top: 1.25rem !important;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ck-style .ck-editor__editable_inline {
  height: 250px;
}

.Polaris-Button .Polaris-Button--primary {
  width: 150px;
}

.accordion-button:focus {
  /* border-color: white !important; */
  box-shadow: none !important;
  background-color: white !important;
}

.accordion-button:not(.collapsed) {
  background-color: white !important;
  color: black !important;
}

.accordion-button .collapsed {
  font-weight: 600 !important;
}

.accordion-item .accordion-button {
  font-weight: 600;
}

.Polaris-Layout__Section,
.Polaris-Layout__AnnotatedSection {
  margin-left: 0 !important
}

.ck-style {
  padding-top: 20px;
}

.NoDataFound {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Polaris-Button--primary {
  text-align: end;
}

.pagination {
  cursor: pointer;
  padding: 0px 0 10px 0px;
  display: flex;
  justify-content: center;
}

.listbtn {
  text-align: end !important;
  position: relative;
  right: 22px;
  top: -25px;
}

.Polaris-Page__Content {
  padding-top: 0;
}

.Polaris-Label__Text {
  margin-top: 1.25rem;
}

.Polaris-LegacyCard__Section {
  padding-top: 0 !important;
}

.text .Polaris-TextField {
  margin-top: 35px !important;
  margin-bottom: 15px !important;
}

.Polaris-Text--headingMd {
  display: inline-block !important;
}

.navbtn {
  display: block;
  text-align: start !important;
}

.Polaris-ButtonGroup {
  cursor: pointer;
  flex-wrap: nowrap !important
}

.table table tr td {
  border: 1px solid !important;
}

.Polaris-Page__Content {
  margin-bottom: 30px;
}

.accordion {
  margin-bottom: 25px;
  padding: 0 20px 20px 20px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.back_btn {
  padding: 0 0 10px 0;
  display: flex;
}

.back_btn .Polaris-Button {
  height: 40px;
  width: 40px;
}

.back-button .Polaris-Icon {
  height: 12px;
  width: 16px;
}

.back_btn .Polaris-Button .Polaris-Button__Content .Polaris-Button__Text a .Polaris-Icon {
  margin: 0 !important
}

.faq_title {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  padding-left: 15px;
  padding-top: 8px;
}

.Polaris-DataTable__Table thead tr th:nth-child(1) {
  width: 80%;
  ;
}